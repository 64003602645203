import React, { useRef } from "react";
import { useIntl, Link } from "gatsby-plugin-intl";

import TagList from "./tagList";
import TeaserMedia from "./teaserMedia";
import Diamond from "../assets/images/svg/diamond.svg";

const LinkWrapper = ({
  children,
  className,
  url,
  openInNewTab,
  onMouseEnter,
  onMouseLeave,
  onTouchStart,
}) => {
  if (openInNewTab) {
    return (
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={className}
        to={url}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
      >
        {children}
      </Link>
    )
  }
}

const TeaserExclusive = ({
  item,
  isBanner = false,
  openInNewTab = true,
}) => {
  const intl = useIntl()
  const videoRef = useRef(null)
  
  const url = item.slug && item.slug.current ? `/exclusive/${item.slug.current}` : '/'
  const headline = item.title[intl.locale]
  const client = item.customer && item.customer && item.customer.customer && item.customer.customer.title ? item.customer.customer.title[intl.locale] : null
  const imageData = item.poster && item.poster.asset ? item.poster.asset.gatsbyImageData : null
  const assetURL = item.video !== "" ? item.video : item.poster.asset.gatsbyImageData.images.fallback.src
  const type = item.video ? "video" : "image"
  const tags = item.tags

  function toggleVideoPlay() {
    const video = videoRef.current

    if (!video) return

    const isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;

    if (isPlaying) {
      video.pause()
    } else {
      video.play()
    }
  }
  
  if (isBanner) {
    return (
      <LinkWrapper
        className="tro-teaser-case"
        url={url}
        openInNewTab={openInNewTab}
        onMouseEnter={toggleVideoPlay}
        onMouseLeave={toggleVideoPlay}
      >
        <div className="flex items-center px-4 font-sans text-black large:hidden text-2xs small:text-xs medium:text-sm mb-2.5 medium:px-8 large:px-3">
            <Diamond className="mr-2 w-2.5 h-2.5" />   <span className="pt-1">TRO Exclusive</span>
        </div>
        <div
          className={`tro-grid-responsive transition-all duration-500 ease-customTeaserPadding bg-black hover:bg-opacity-75`}
        >
          <div
            className={`transition-all duration-500 ease-customTeaserPadding flex flex-col justify-end px-4 medium:px-8 large:px-3 pt-3 pb-2 medium:pb-4 col-span-8 large:col-span-4 order-2 large:order-2 large:pl-0 large:pr-5 relative`}
          >
                <div className="absolute left-0 items-center justify-center hidden font-sans text-sm text-white large:flex top-6">
                    <Diamond className="mr-2 w-2.5 h-2.5" />   <span className="pt-0.5">TRO Exclusive</span>
                </div>
            <div className="pb-1 font-sans text-white text-2xs small:text-xs medium:text-sm">
              {client}
            </div>
            <h2 className="pb-3 font-sans text-lg text-white small:text-xl medium:text-2xl big:text-3xl">
              {headline}
            </h2>
            <TagList tags={tags} style="teaser" blackTheme="true" />
          </div>

          <div className="relative order-1 overflow-hidden bg-white col-span-8 small:col-span-12 large:col-span-8 aspect-video" style={{aspectRatio: '16 / 9'}}>
            <TeaserMedia
              type={type}
              origin={"case"}
              imageData={imageData}
              url={assetURL}
              videoRef={videoRef}
            />
          </div>
        </div>
      </LinkWrapper>
    );
  } else {
    return (
      <LinkWrapper
        className="flex flex-col tro-teaser-case transition-all duration-500 ease-customTeaserPadding"
        url={url}
        openInNewTab={openInNewTab}
        onMouseEnter={toggleVideoPlay}
        onMouseLeave={toggleVideoPlay}
        onTouchStart={toggleVideoPlay}
      >
        <TeaserMedia 
          type={type} 
          origin={"case"} 
          url={assetURL} 
          imageData={imageData}
          videoRef={videoRef} 
        />
        <div className="py-3 pr-6 tro-insight-content-default transition-all duration-500 ease-customTeaserPadding">
          <div className="pb-1 font-sans text-2xs small:text-xs medium:text-sm">
            {client}
          </div>
          <h2 className="pb-3 font-sans text-lg small:text-xl medium:text-2xl">
            {headline}
          </h2>
          <TagList tags={tags} style="teaser" />
        </div>
      </LinkWrapper>
    );
  }
};

export default TeaserExclusive;
