import React, { useRef } from "react";
import { useIntl, Link } from "gatsby-plugin-intl";

import TagList from "./tagList";
import TeaserMedia from "./teaserMedia";

const LinkWrapper = ({
  children,
  className,
  url,
  openInNewTab,
  onMouseEnter,
  onMouseLeave,
  onTouchStart,
}) => {
  if (openInNewTab) {
    const { locale } = useIntl();
    const localizedUrl = `/${locale}${url}`; // TODO after an update of gatsby-plugin-intl, change that to generatePath

    return (
      <a
        className={className}
        href={localizedUrl}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={className}
        to={url}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
      >
        {children}
      </Link>
    )
  }
}

const TeaserCase = ({
  item,
  isBanner = false,
  bannerProperties = {
    color: "bg-brand-lighter",
    isTextRight: false,
  },
  hideTags = false,
  openInNewTab = true,
}) => {
  const intl = useIntl()
  const videoRef = useRef(null)
  
  const url = item.slug && item.slug.current ? `/project/${item.slug.current}` : '/'
  const headline = item.title[intl.locale]
  const client = item.customer && item.customer && item.customer.customer && item.customer.customer.title ? item.customer.customer.title[intl.locale] : null
  const imageData = item.poster && item.poster.asset ? item.poster.asset.gatsbyImageData : null
  const assetURL = item.video !== "" ? item.video : item.poster.asset.gatsbyImageData.images.fallback.src
  const type = item.video ? "video" : "image"
  const tags = item.tags

  function toggleVideoPlay() {
    const video = videoRef.current

    if (!video) return

    const isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;

    if (isPlaying) {
      video.pause()
    } else {
      video.play()
    }
  }

  if (isBanner) {
    return (
      <LinkWrapper
        className="tro-teaser-case"
        url={url}
        openInNewTab={openInNewTab}
        onMouseEnter={toggleVideoPlay}
        onMouseLeave={toggleVideoPlay}
        // onTouchStart={toggleVideoPlay}
      >
        <div
          className={`tro-grid-responsive transition-all duration-500 ease-customTeaserPadding ${
            bannerProperties.color
          } ${
            bannerProperties.color === "bg-brand-lighter"
              ? "hover:bg-brand"
              : "hover:bg-brand-lighter"
          }`}
        >
          <div
            className={`transition-all duration-500 ease-customTeaserPadding flex flex-col justify-end px-4 medium:px-8 large:px-3 pt-3 pb-2 medium:pb-4 col-span-8 large:col-span-4 ${
              bannerProperties.isTextRight
                ? "order-2 large:order-2 large:pl-0 large:pr-5"
                : "order-2 large:order-1 large:pl-5 large:pr-0"
            }`}
          >
            <div className="pb-1 font-sans text-black text-2xs small:text-xs medium:text-sm">
              {client}
            </div>
            <h2 className="pb-3 font-sans text-lg text-black small:text-xl medium:text-2xl big:text-3xl">
              {headline}
            </h2>
            {!hideTags && (
              <TagList tags={tags} style="teaser" />
            )}
          </div>

          <div className="relative order-1 overflow-hidden bg-white col-span-8 small:col-span-12 large:col-span-8">
            <TeaserMedia
              type={type}
              origin={"case"}
              imageData={imageData}
              url={assetURL}
              videoRef={videoRef}
            />
          </div>
        </div>
      </LinkWrapper>
    );
  } else {
    return (
      <LinkWrapper
        className="flex flex-col tro-teaser-case transition-all duration-500 ease-customTeaserPadding"
        url={url}
        openInNewTab={openInNewTab}
        onMouseEnter={toggleVideoPlay}
        onMouseLeave={toggleVideoPlay}
        onTouchStart={toggleVideoPlay}
      >
        <TeaserMedia 
          type={type} 
          origin={"case"} 
          url={assetURL} 
          imageData={imageData}
          videoRef={videoRef} 
        />
        <div className="py-3 pr-6 tro-insight-content-default transition-all duration-500 ease-customTeaserPadding">
          <div className="pb-1 font-sans text-2xs small:text-xs medium:text-sm">
            {client}
          </div>
          <h2 className="pb-3 font-sans text-lg small:text-xl medium:text-2xl">
            {headline}
          </h2>
          {!hideTags && (
            <TagList tags={tags} style="teaser" />
          )}
        </div>
      </LinkWrapper>
    );
  }
};

export default TeaserCase;
