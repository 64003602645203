import * as React from "react";

import ArrowRight from "../assets/images/svg/arrow-right.svg";

const Callout = ({ headline, subline, ctaLink }) => {
  return (
    <div className="tro-callout tro-grid-responsive">
      {headline && (
        <h4 className="text-lg font-black small:text-xl medium:text-xl large:text-2xl big:text-3xl col-start-1 col-span-6 medium:col-span-12 large:col-span-8">
          {headline}
        </h4>
      )}
      
      {subline && (
        <p className="font-sans text-lg font-light small:text-lg medium:text-xl large:text-2xl big:text-3xl col-start-1 col-span-6 medium:col-span-12 large:col-span-8">
          {subline}
        </p>
      )}
      
      {ctaLink && (
        <a
          href={ctaLink}
          className="relative pt-5 text-right col-start-1 col-span-6 medium:col-span-12 large:col-span-8"
        >
          <span className="mr-7 tro-button-primary">Mehr erfahren</span>
          <ArrowRight className="absolute right-0 block h-2 bottom-0.5 small:bottom-1.5 medium:bottom-1 medium:h-auto" />
        </a>
      )}
    </div>
  );
};

export default Callout;
