import * as React from "react";
import { useIntl, navigate } from "gatsby-plugin-intl";

const TagList = ({ blackTheme = false, tags = [], isCustomHover, style = null, selected = null, teaser = true, showAll = false }) => {
  const intl = useIntl();
  
  function onClick (e) {
    e.preventDefault();
    navigate('/projects', { state: { selected: e.target.dataset.tag } })
  }
  
  if (showAll && !tags.find(el => el.key === 'all')) {
    const allObject = { key: "all", title: {}};
    allObject.title[intl.locale] = intl.formatMessage({ id: "all" })
    tags.unshift(allObject)
  }
  
  return (
    <div className="flex flex-wrap">
      {tags.map((item, index) => (
        <button
          onClick={onClick}
          data-tag={item.key}
          key={index}
          className={`tro-button-outline ${blackTheme ? 'tro-button-outline--dark' : ''} ${
            item.key === selected
              ? "bg-brand border-transparent"
              : `${blackTheme ? 'border-white' : 'border-brand-darkgrey'}`
          } ${style === "teaser" ? "tro-button-outline--teaser" : ""}`}
        >
          {item.title[intl.locale]}
        </button>
      ))}
    </div>
  );
};

export default TagList;
