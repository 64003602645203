import * as React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";

import moment from "moment";

import TeaserMedia from "./teaserMedia";

const LinkWrapper = ({
  children,
  className,
  url,
  openInNewTab,
}) => {
  if (openInNewTab) {
    return (
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={className}
        to={url}
      >
        {children}
      </Link>
    )
  }
}

const TeaserInsight = ({
  item,
  customStyle = null,
  openInNewTab = true,
}) => {
  const intl = useIntl()
  
  const url = `/insight/${item.slug.current}`
  const headline = item.title ? item.title[intl.locale] : null
  const assetURL = item.poster && item.poster.asset ? item.poster.asset.gatsbyImageData.images.fallback.src : null
  const imageData = item.poster && item.poster.asset ? item.poster.asset.gatsbyImageData : null
  const author = item.author
  const date = item.postdatetime
  
  const HorizontalCopyElements = ({ style = null }) => (
    <>
      <div
        className={`${
          style === "right" ? "justify-end" : ""
        } hidden flex-row content-center pb-1 font-sans leading-none text-2xs small:text-sm medium:flex`}
      >
        <div className="w-2 h-2 mt-1 mr-2 transition duration-200 ease-in-out bg-brand transform rotate-45 tro-teaser-insight-elements-diamond"></div>
        TRO Insight
      </div>
      <div className="flex flex-col-reverse medium:flex-col">
        <h2 className={`${
          style === "right" ? "medium:text-right" : ""
        } pb-5 font-sans text-lg font-light small:text-xl medium:text-2xl`}>
          {headline}
        </h2>
        <div className={`${
          style === "right" ? "medium:text-right" : ""
        } pb-3 font-sans text-2xs small:text-xs medium:text-sm medium:pb-0`}>
          {author}
          <br />
          {moment(date).format("MMMM YYYY")}
        </div>
      </div>
    </>
  );
  if (customStyle === "left") {
    return (
        <LinkWrapper
          className="flex flex-col medium:flex-row tro-teaser-insight transition duration-200 ease-in-out hover:text-black"
          url={url}
          openInNewTab={openInNewTab}
        >
          <div className="flex-auto medium:pb-0 medium:w-1/2">
            <TeaserMedia type="image" imageData={imageData} />
          </div>
          <div className="flex flex-col justify-between flex-auto px-0 pt-3 medium:w-1/2 tro-insight-content transition-all duration-500 ease-customTeaserPadding medium:pt-0 medium:px-8">
            <HorizontalCopyElements />
          </div>
        </LinkWrapper>
    );
    
  } else if (customStyle === "right") {
    return (
      <LinkWrapper
        className="flex flex-col medium:flex-row tro-teaser-insight transition duration-200 ease-in-out hover:text-black"
        url={url} 
        openInNewTab={openInNewTab}
      >
        <div className="flex flex-col justify-between flex-auto px-0 pt-3 medium:w-1/2 tro-insight-content transition-all duration-500 ease-customTeaserPadding medium:pt-0 medium:px-8">
          <HorizontalCopyElements style="right" />
        </div>
        <div className="flex-auto medium:pb-0 medium:w-1/2">
          <TeaserMedia type="image" imageData={imageData} />
        </div>
      </LinkWrapper>
    );
  } else {
    return (
      <LinkWrapper
        className="flex flex-col tro-teaser-insight"
        url={url}
        openInNewTab={openInNewTab}
      >
        <TeaserMedia type="image" origin={"insight"} imageData={imageData} />
        <div className="py-3 pr-6 tro-insight-content-default transition-all duration-500 ease-customTeaserPadding">
          <div className="font-sans text-2xs small:text-xs medium:text-sm">
            {author}
          </div>
          <div className="pb-4 font-sans text-2xs small:text-xs medium:text-sm">
            {moment(date).format("MMMM YYYY")}
          </div>
          <h2 className="font-sans text-lg font-light medium:text-xl tablet:text-2xl large:text-3xl">
            {headline}
          </h2>
        </div>
      </LinkWrapper>
    );
  }
};

export default TeaserInsight;
