import * as React from "react";

import SvgWhite from "../assets/images/svg/lock-white.svg";
import SvgBlack from "../assets/images/svg/lock-black.svg";

import moment from "moment";

const PageHead = ({
  topline = "",
  headline,
  subline,
  customClass = "",
  singleDiamond = true,
  backgroundImage = false,
  backgroundVideo = null,
  textWhite = false,
  locked = false,
  author,
  authorprofile,
  postdatetime,
}) => {
  
  const style = {}
  
  const gradient = textWhite ? 'linear-gradient(rgba(0,0,0, 0), rgba(0,0,0, 0.8))' : 'linear-gradient(rgba(255,255,255, 0), rgba(255,255,255, 0.8))' 
  
  if (backgroundImage) {
    style['backgroundImage'] = `${gradient}, url(${backgroundImage})`
  }
  
  return (
    <header className={`relative mb-5 medium:mb-8 min-h-70vh ${customClass} ${ textWhite ? 'text-white' : '' }`}>
      
      <div className={`absolute z-0 top-0 left-0 w-full h-full  ${ backgroundImage ? 'bg-cover bg-center' : '' }`} style={style}></div>
      
      {backgroundVideo && (
        <div className={`absolute z-10 top-0 left-0 w-full h-full`}>
          <video
            loop
            muted
            playsInline
            autoPlay
            className="absolute top-0 left-0 z-0 object-cover w-full h-full"
          >
            <source src={backgroundVideo} type="video/mp4" />
          </video>
          <div className={`absolute z-10 top-0 left-0 w-full h-full`} style={{backgroundImage: gradient}}></div>
        </div>
      )}
      
      <div className="absolute top-0 left-0 z-20 w-full h-full pb-8 tro-default-padding-x tro-grid-responsive">
        <div className="flex flex-col justify-end col-span-5 medium:col-span-8">
          {topline && (
            <span className="pb-1 font-sans text-xs medium:text-sm">{topline}</span>
          )}
          <h1>
            {locked && textWhite && (
              <SvgWhite className="w-5 mb-2 mr-2 inline-block" />
            )}
            {locked && !textWhite && (
              <SvgBlack className="w-5 mb-2 mr-2 inline-block" />
            )}
            {headline}
          </h1>
          <p className="font-sans text-lg font-light small:text-xl medium:text-2xl large:text-3xl big:text-4xl">
            {subline}
          </p>
          {author && (
            <div>
              <div className="font-sans text-2xs small:text-xs medium:text-sm">
                {authorprofile && (
                  <a href={authorprofile} className="hover:text-gray-800 transition-colors duration-100" target="_blank">{author} &rarr;</a>
                )}
                {!authorprofile && (
                  <span>{author}</span>
                )}
              </div>
            </div>
          )}
          {postdatetime && (
            <div className="font-sans text-2xs small:text-xs medium:text-sm">
              {moment(postdatetime).format("DD.MM.YYYY")}
            </div>
          )}
        </div>

        {singleDiamond ? (
          <div className="absolute w-2 h-2 bottom-10 right-5 medium:w-4 medium:h-4 medium:right-9 bg-brand transform rotate-45"></div>
        ) : ''}
      </div>
    </header>
  );
};

export default PageHead;
