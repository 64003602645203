import React, { useEffect } from "react";
import {GatsbyImage} from 'gatsby-plugin-image'

import ImageFallback from "../assets/images/svg/image-fallback.svg";
import Diamond from "../assets/images/svg/diamond.svg";

const TeaserMedia = ({ 
  type, 
  origin = "", 
  url = false, 
  videoRef = null,
  imageData = null,
  diamond = true
}) => {
  
  if (!url && !imageData) {
    return (
      <div className="relative w-full h-full tro-teaser-fallback">
        <ImageFallback className="object-cover w-full h-full p-2 tro-fallback-image medium:p-4 transition duration-200 ease-in-out" />
       {diamond && <Diamond className="absolute w-8 h-8 tro-diamond bottom-3 right-3" /> }
      </div>
    );
  }

  if (type === "image") {
    return (
      <div className="relative object-cover w-full h-full tro-teaser-media">
        <GatsbyImage image={imageData} alt={""} className="object-cover w-full h-full" />
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full ">
          {diamond &&  <Diamond
            className={`tro-diamond ${
              origin === "case__BAK"
                ? "w-30p h-30p"
                : "absolute w-6 h-6 bottom-3 right-3 tablet:bottom-4 tablet:right-4 tablet:w-8 tablet:h-8"
            }`}
          /> }
        </div>
      </div>
    );
  }

  if (type === "video") {
    const bgImage = imageData && imageData.images && imageData.images.fallback && imageData.images.fallback.src ? imageData.images.fallback.src : '';
    return (
      <div className="relative w-full h-full tro-teaser-media" style={{"background": `url(${bgImage}) center center / cover no-repeat transparent`}}>
        <video
          loop
          muted
          playsInline
          className="invisible object-cover w-full h-full cursor-pointer medium:visible"
          ref={videoRef}
        >
          <source src={`${url}#t=0.1`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full pointer-events-none">
        {diamond && <Diamond
            className={`tro-diamond ${
              origin === "case__BAK"
                ? "w-30p h-30p"
                : "absolute w-6 h-6 bottom-3 right-3 tablet:bottom-4 tablet:right-4 tablet:w-8 tablet:h-8"
            }`}
          /> }
        </div>
      </div>
    );
  }
};

export default TeaserMedia;
